@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'prismjs/themes/prism-tomorrow.css';
@import 'leaflet/dist/leaflet.css';
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

@layer components {
  .img-select-none {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

html,
body {
  max-width: 100vw;
  color: black;
  overflow-x: hidden;
}

body {

  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}